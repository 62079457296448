import {
  ChangeEmailData, ChangePhoneData, SendOtpFormData, UpdateProfileData,
} from '../types/accounts';
import HttpHelpers from './helpers';

const AccountsApiEndpoints = {
  sendOtp: (data: SendOtpFormData) => HttpHelpers.authenticatedAxios
    .post('/api/v2/accounts/send_otp', data)
    .then((response) => response.data),
  getProfile: () => HttpHelpers.authenticatedAxios
    .get('/api/v2/accounts/profile')
    .then((response) => response.data),
  updateProfile: (data: UpdateProfileData) => HttpHelpers.authenticatedAxios
    .patch('/api/v2/accounts/profile', data)
    .then((response) => response.data),
  updateProfilePicture: (data: any) => HttpHelpers.authenticatedAxios
    .put('/api/v2/accounts/profile_picture', data)
    .then((response) => response.data),
  changeEmail: (data: ChangeEmailData) => HttpHelpers.authenticatedAxios
    .post('/api/v2/accounts/change_email', data)
    .then((response) => response.data),
  changePhoneNumber: (data: ChangePhoneData) => HttpHelpers.authenticatedAxios
    .post('/api/v2/accounts/change_phone', data)
    .then((response) => response.data),
};

export default AccountsApiEndpoints;
