import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo.svg';
import appStore from '../../assets/images/app-store.svg';
import playStore from '../../assets/images/play-store.svg';

const APP_STORE_URL = 'https://apps.apple.com/sa/app/chaizer-%D8%B4%D8%A7%D9%8A%D8%B2%D8%B1/id1483186108';
const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.shareasy.saudiarabia&hl=en&gl=US';

function Download() {
  const { t } = useTranslation();

  useEffect(() => {
    const { userAgent } = navigator;
    const isAndroid = /Android/i.test(userAgent);
    const isIphone = /iPhone/i.test(userAgent);

    if (isAndroid) {
      window.location.href = 'chaizer://test';
      setTimeout(() => {
        window.location.href = PLAY_STORE_URL;
      }, 2000);
    }

    if (isIphone) {
      window.location.href = 'chaizer://test';
      setTimeout(() => {
        window.location.href = APP_STORE_URL;
      }, 2000);
    }
  }, []);

  return (
    <div className='flex items-center justify-center min-h-screen text-center'>
      <div>
        <img src={logo} alt='chaizer' width={150} height={120} className='mx-auto' />
        <h1 className='mt-6 text-3xl font-bold tracking-tight text-black sm:text-3xl'>{t('download.title')}</h1>
        <p className='mt-3 mb-0 text-base leading-7 text-secondary'>{t('download.subtitle')}</p>
        <div className='flex gap-3 items-center justify-center'>
          <Link to={APP_STORE_URL}>
            <img src={appStore} alt='chaizer' className='w-40' />
          </Link>
          <Link to={PLAY_STORE_URL}>
            <img src={playStore} alt='chaizer' className='w-40' />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Download;
