import { useForm } from 'react-hook-form';
import { Params, useLoaderData, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../components/input';
import Api from '../../services';
import { CompanyProfileFormData } from '../../types/companies';
import Spinner from '../../components/spinner';
import Alert from '../../components/alert';

export const CompanyProfileLoader = async ({ params }: { params: Params }) => {
  const { verificationCode } = params;

  const response = await Api.companies.getCompanyProfile(verificationCode);

  if (response.data.status === 400) {
    throw new Error('Could not fetch company profile');
  }

  return response.data;
};

export default function CompanyProfile() {
  const params = useParams();
  const data = useLoaderData();
  const [loading, setLoading] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const { t } = useTranslation();

  const {
    register, handleSubmit, formState: { errors }, reset,
  } = useForm<CompanyProfileFormData>();

  useEffect(() => {
    if (!data) return;

    reset(data);
  }, [data]);

  const onSubmit = (data: CompanyProfileFormData) => {
    setLoading(true);
    Api.companies.completeCompanyProfile(params.verificationCode, data).then(() => {
      setIsAlertVisible(true);
      setLoading(false);
      setTimeout(() => {
        setIsAlertVisible(false);
      }, 5000);
    }).catch(() => {
      setLoading(false);
    });
  };
  return (
    <>
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
        <form onSubmit={handleSubmit(onSubmit)} className='mx-auto max-w-3xl pb-10'>
          <h1 className='font-semibold text-2xl text-black mt-10 text-center mb-6'>{t('companyProfile.title')}</h1>
          <div className='bg-white shadow sm:rounded-md px-8 pt-6 pb-4 rounded-md mb-6'>
            <h2 className='text-lg font-medium text-primary text-black mb-4'>
              {t('companyProfile.companyDetails.title')}
            </h2>
            <div className='col-span-full'>
              <InputField
                label={t('companyProfile.companyDetails.commercialName')}
                type='text'
                errors={errors}
                {...register('commercial_name')}
              />
            </div>
            <div className='col-span-full'>
              <InputField
                label={t('companyProfile.companyDetails.brandName')}
                type='text'
                errors={errors}
                {...register('brand_name')}
              />
            </div>
            <div className='col-span-full'>
              <InputField
                label={t('companyProfile.companyDetails.city')}
                type='text'
                errors={errors}
                {...register('city')}
              />
            </div>
            <div className='col-span-full'>
              <InputField
                label={t('companyProfile.companyDetails.district')}
                type='text'
                errors={errors}
                {...register('district')}
              />
            </div>
            <div className='col-span-full'>
              <InputField
                label={t('companyProfile.companyDetails.crNumber')}
                type='text'
                errors={errors}
                {...register('cr_number')}
                dir='ltr'
              />
            </div>
            <div className='col-span-full'>
              <InputField
                label={t('companyProfile.companyDetails.vatNumber')}
                type='text'
                errors={errors}
                {...register('vat_number')}
                dir='ltr'
              />
            </div>

          </div>
          <div className='bg-white shadow sm:rounded-md px-8 pt-6 pb-4 rounded-md'>
            <h2 className='text-lg font-medium text-primary text-black mb-4'>
              {t('companyProfile.representativeDetails.title')}
            </h2>
            <div className='col-span-full'>
              <InputField
                label={t('companyProfile.representativeDetails.representativeName')}
                type='text'
                errors={errors}
                {...register('representative_name')}
              />
            </div>
            <div className='col-span-full'>
              <InputField
                label={t('companyProfile.representativeDetails.representativePhone')}
                type='tel'
                errors={errors}
                {...register('representative_phone')}
                dir='ltr'
              />
            </div>
            <div className='col-span-full'>
              <InputField
                label={t('companyProfile.representativeDetails.representativeEmail')}
                type='email'
                errors={errors}
                {...register('representative_email')}
                dir='ltr'
              />
            </div>
            <div className='col-span-full'>
              <InputField
                label={t('companyProfile.representativeDetails.representativeIdNumber')}
                type='text'
                errors={errors}
                {...register('representative_id_number')}
                dir='ltr'
              />
            </div>
            <div className='col-span-full'>
              <InputField
                label={t('companyProfile.representativeDetails.representativeNationality')}
                type='text'
                errors={errors}
                {...register('representative_nationality')}
              />
            </div>
          </div>
          <div className='mt-6 flex justify-end'>
            <button
              type='submit'
              className='flex justify-center rounded-md bg-primary px-10 py-2 text-base font-medium leading-6 text-white shadow-sm hover:bg-lime-600 disabled:bg-lime-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600'
            >
              {loading ? <Spinner /> : t('shared.save')}
            </button>
          </div>
        </form>
      </div>
      <Alert
        title={t('companyProfile.successAlertTitle')}
        text={t('companyProfile.successAlertSubtitle')}
        isAlertVisible={isAlertVisible}
        onClose={() => setIsAlertVisible(false)}
      />
    </>
  );
}
