import { ActivateItemData } from '../types/items';
import HttpHelpers from './helpers';

const ItemsApiEndpoints = {
  activateItem: (data: ActivateItemData) => HttpHelpers.authenticatedAxios
    .post('/api/v2/customer/items/activate', data)
    .then((response) => response.data),
};

export default ItemsApiEndpoints;
