import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EnglishTranslation from './locales/en.json';
import ArabicTranslation from './locales/ar.json';

const resources = {
  en: {
    translation: EnglishTranslation,
  },
  ar: {
    translation: ArabicTranslation,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'ar',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
