import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <main className='grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8'>
      <div className='text-center'>
        <p className='text-2xl font-semibold text-primary'>404</p>
        <h1 className='mt-4 text-3xl font-bold tracking-tight text-black sm:text-5xl'>{t('notFound.title')}</h1>
        <p className='mt-6 text-base leading-7 text-secondary'>{t('notFound.subtitle')}</p>
        <div className='mt-10 flex items-center justify-center'>
          <Link
            to='/'
            className='rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600'
          >
            {t('notFound.cta')}
          </Link>
        </div>
      </div>
    </main>
  );
}
