import EmailForm from './email';
import PhoneForm from './phone';

export default function AccountCredentials() {
  return (
    <div className='space-y-6 sm:px-6 lg:col-span-9 lg:px-0'>
      <EmailForm />
      <PhoneForm />
    </div>
  );
}
