import { Fragment, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

interface AlertProps {
  title: string;
  text: string;
  isAlertVisible: boolean;
  onClose: () => void;
}

export default function Alert({
  title, text, isAlertVisible, onClose,
}: AlertProps) {
  useEffect(() => {
    if (isAlertVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, 5000);
      return () => clearTimeout(timer);
    }

    return undefined;
  }, [isAlertVisible, onClose]);

  return (
    <div
      aria-live='assertive'
      className={`${
        isAlertVisible ? 'animate-enter' : 'animate-leave'
      } pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6`}
    >
      <div className='flex w-full flex-col items-center space-y-4 sm:items-center'>
        <Transition
          show={isAlertVisible}
          as={Fragment}
          enter='transform ease-out duration-300 transition'
          enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
          enterTo='translate-y-0 opacity-100 sm:translate-x-0'
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
            <div className='p-4'>
              <div className='flex items-start'>
                <div className='flex-shrink-0'>
                  <CheckCircleIcon className='h-6 w-6 text-primary' aria-hidden='true' />
                </div>
                <div className='rtl:ml-3 mr-3 rtl:mr-3 w-0 flex-1 pt-0.5'>
                  <p className='text-sm font-medium text-black'>{title}</p>
                  <p className='mt-1 text-sm text-secondary'>{text}</p>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}
