import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import VerificationInput from 'react-verification-input';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Api from '../../../../../services';
import { AppDispatch } from '../../../../../redux/store';
import { getProfile } from '../../../../../redux/profile';

type ModalProps = {
  isModalOpen: boolean;
  toggleModal: (value: boolean) => void;
  phoneNumber: string;
}

export default function UpdatePhone({ isModalOpen, toggleModal, phoneNumber }: ModalProps) {
  const [error, setError] = useState('');
  const [timeLeft, setTimeLeft] = useState(180);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!timeLeft) return undefined;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  const resendOtp = () => {
    Api.accounts
      .sendOtp({
        username: phoneNumber,
        ensure_not_registered: true,
      })
      .then(() => {
        setTimeLeft(180);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const verifyOtp = async (otp: string) => {
    Api.accounts.changePhoneNumber({
      phone_number: phoneNumber,
      token: otp,
    }).then(() => {
      dispatch(getProfile());
      toggleModal(false);
    }).catch(() => {
      setError(t('shared.otpError'));
    });
  };
  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={toggleModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black/25' />
        </Transition.Child>
        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-right align-middle shadow-xl transition-all'>
                <Dialog.Title className='font-semibold text-lg text-black'>
                  {t('account.credentials.verifyPhone')}
                </Dialog.Title>
                <Dialog.Description className='mt-2 mb-7 text-base font-medium text-secondary'>
                  {t('account.update.subtitle')}
                  {' '}
                  {phoneNumber}
                </Dialog.Description>
                <div className='flex flex-row items-center justify-center' dir='ltr'>
                  <VerificationInput
                    classNames={{
                      container: 'w-full',
                      character: 'w-full h-11/12 flex flex-col items-center justify-center text-center px-5 rounded-xl border border-gray-200 text-lg bg-white',
                      characterInactive: 'text-secondary',
                      characterSelected: 'focus:bg-gray-50 focus:ring-1 focus:ring-inset focus:ring-primary outline-primary text-primary',
                      characterFilled: 'text-primary',
                    }}
                    autoFocus
                    onComplete={verifyOtp}
                  />
                </div>
                {error && <p className='text-red-500 text-xs mt-2'>{error}</p>}
                {timeLeft > 0 ? (
                  <p className='mt-6 text-center font-semibold leading-6 text-secondary'>
                    {t('auth.verification.requestOtp')}
                    {' '}
                    <span className='text-primary'>
                      {`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
                    </span>
                  </p>
                ) : (
                  <button
                    type='button'
                    className='mt-6 mx-auto text-center flex justify-center font-semibold leading-6 text-primary cursor-pointer'
                    onClick={resendOtp}
                  >
                    {t('auth.verification.resendOtp')}
                  </button>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
