import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../redux/store';
import PhoneField from '../../../../components/phone-input';
import Api from '../../../../services';
import UpdatePhone from './update';
import Alert from '../../../../components/alert';
import Spinner from '../../../../components/spinner';

type FormData = {
  phone_number: string;
}

export default function PhoneForm() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const profile = useSelector((state: RootState) => state.profile.data);
  const { t } = useTranslation();
  const {
    register, handleSubmit, formState: { errors }, reset, control, setError, getValues,
  } = useForm<FormData>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (!profile?.phone_number) return;

    reset({ phone_number: profile?.phone_number });
  }, [profile, reset]);

  const onSubmit = (data: FormData) => {
    setLoading(true);
    Api.accounts.sendOtp({
      username: data.phone_number,
      ensure_not_registered: true,
    }).then(() => {
      setLoading(false);
      setIsModalOpen(true);
      setIsAlertVisible(true);
      setTimeout(() => {
        setIsAlertVisible(false);
      }, 5000);
    }).catch((error) => {
      setLoading(false);
      const responseErrors = error?.response?.data?.error_data?.username[0];
      setError('phone_number', { message: responseErrors });
    });
  };
  return (
    <>
      <section>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='shadow sm:rounded-md'>
            <div
              className={classNames(
                'bg-white px-4 py-6 sm:p-6 rounded-t-md',
                { 'rounded-b-md': profile?.phone_number_verified_at },
              )}
            >
              <div>
                <h2 className='text-lg font-medium leading-6 text-black'>
                  {t('shared.phone')}
                </h2>
                <p className='mt-1 text-sm text-secondary'>
                  {t('account.update.subtitle')}
                </p>
              </div>
              <div className='mt-6 grid grid-cols-4 gap-x-6'>
                <div className='col-span-4 sm:col-span-2'>
                  <PhoneField
                    label={t('shared.phone')}
                    errors={errors}
                    {...register('phone_number')}
                    control={control}
                    disabled={profile?.phone_number_verified_at !== null}
                  />
                </div>
              </div>
            </div>
            {!profile?.phone_number_verified_at
            && (
              <div className='bg-gray-50 p-3 text-left rounded-b-md'>
                <button
                  type='submit'
                  className='inline-flex justify-center rounded-md bg-primary px-3 py-2 text-base font-medium leading-6 text-white shadow-sm hover:bg-lime-600 disabled:bg-lime-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600'
                  disabled={loading}
                >
                  {loading ? <Spinner /> : t('shared.sendOtp')}
                </button>
              </div>
            )}
          </div>
        </form>
      </section>
      <UpdatePhone
        phoneNumber={getValues('phone_number')}
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
      />
      <Alert
        title={t('account.credentials.verifyPhone')}
        text={t('account.credentials.successAlert')}
        isAlertVisible={isAlertVisible}
        onClose={() => setIsAlertVisible(false)}
      />
    </>
  );
}
