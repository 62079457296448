/* eslint-disable */
export const getErrorsByPath = (errors: Record<string, any>, name: string) => {
  const parts = name.split('.');

  return parts.reduce((currentErrors, part) => {
    if (!currentErrors) return undefined;
    return currentErrors[part];
  }, errors);
};

//@ts-ignore
export const setServerErrors = (responseErrors, setError) => {
  for (const key of Object.keys(responseErrors)) {
    const message = responseErrors[key][0];
    setError(key, { message, type: 'manual' });
  }
}

export const renderGender = (gender: string | null | undefined): "M" | "F" | undefined => {
  switch (gender) {
    case 'male':
      return 'M';
    case 'female':
      return 'F';
    default:
      return undefined;
  }
};
