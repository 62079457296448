import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../../components/input';
import schema from './schema';
import Api from '../../../../services';
import Spinner from '../../../../components/spinner';

export default function EmailForm() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { locale } = useParams();
  const { t } = useTranslation();
  const {
    register, handleSubmit, formState: { errors }, setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = (data: { email: string }) => {
    setLoading(true);
    Api.accounts.sendOtp({
      username: data.email,
    }).then((response) => {
      navigate(`/${locale}/auth/verify_identity`, {
        state: {
          ...location.state,
          username: data.email,
          data: response.data,
          type: 'email',
        },
      });
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      const responseErrors = error?.response?.data?.error_data?.username[0];
      setError('email', { message: responseErrors });
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputField
        label={t('shared.email')}
        type='email'
        errors={errors}
        {...register('email')}
        dir='ltr'
      />
      <button
        type='submit'
        className='flex w-full justify-center rounded-md bg-primary px-3 py-2 text-base font-medium leading-6 text-white shadow-sm hover:bg-lime-600 disabled:bg-lime-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600'
        disabled={loading}
      >
        {loading ? <Spinner /> : t('shared.continue')}
      </button>
    </form>
  );
}
