import React from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Datepicker from 'react-tailwindcss-datepicker';
import classNames from 'classnames';
import { getErrorsByPath } from '../../helpers';

interface DateFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  errors: object;
  control: any;
}

function DateField({
  name, control, errors, label,
}: DateFieldProps) {
  return (
    <div className='mb-5'>
      <label className='block text-sm font-medium leading-6 text-black'>
        {label}
      </label>
      <div className='mt-2' dir='ltr'>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <Datepicker
              maxDate={new Date()}
              value={value}
              onChange={onChange}
              useRange={false}
              asSingle
              containerClassName='relative'
              inputClassName={classNames(
                'w-full rounded-md border-0 py-2.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6',
                { 'ring-red-300': getErrorsByPath(errors, name) },
              )}
            />
          )}
        />
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className='text-red-500 text-xs mt-2'>{message}</p>}
      />
    </div>
  );
}

export default DateField;
