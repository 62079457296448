import { ReactElement, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import i18n from '../../i18n';

interface InitializeAppProps {
  children: ReactElement | null;
}

const DEFAULT_LOCALE = 'ar';
const VALID_LOCALES = ['ar', 'en'];

function InitializeApp({ children }: InitializeAppProps) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const localeFromPath = pathSegments[0];

    if (!VALID_LOCALES.includes(localeFromPath)) {
      const newPath = `/${DEFAULT_LOCALE}${location.pathname}`;
      navigate(newPath, { replace: true });
    } else if (i18n.resolvedLanguage !== localeFromPath) {
      if (i18n.options.fallbackLng) {
        i18n.changeLanguage(localeFromPath);
        document.documentElement.lang = localeFromPath;
        const direction = localeFromPath === 'ar' ? 'rtl' : 'ltr';
        document.documentElement.dir = direction;
      }
    }
  }, [navigate, location]);

  return children;
}

export default InitializeApp;
