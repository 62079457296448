import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { getErrorsByPath } from '../../helpers';

interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  errors: object;
}

const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
  const {
    label, name, errors = {}, ...inputProps
  } = props;

  return (
    <div className='mb-5'>
      <label className='block text-sm font-medium leading-6 text-black'>
        {label}
      </label>
      <div className='mt-2'>
        <input
          {...inputProps}
          ref={ref}
          name={name}
          className={classNames(
            'block w-full rounded-md border-0 py-2.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-primary disabled:bg-zinc-100 sm:text-sm sm:leading-6',
            { 'ring-red-300': getErrorsByPath(errors, name) },
          )}
          autoComplete='off'
        />
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className='text-red-500 text-xs mt-2'>{message}</p>}
      />
    </div>
  );
});

export default InputField;
