import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo.svg';

export default function AuthLayout() {
  const location = useLocation();
  const { t } = useTranslation();

  const getHeading = () => {
    if (location.pathname.includes('/auth/login')) {
      return t('auth.login.title');
    } if (location.pathname.includes('/auth/verify_identity')) {
      return t('auth.verification.title');
    }
    return '';
  };
  return (
    <div className='relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-12'>
      <div className='relative bg-white px-6 pt-10 pb-10 shadow-xl mx-auto w-full max-w-lg rounded-2xl'>
        <div className='mx-auto flex w-full max-w-md flex-col'>
          <div className='flex flex-col items-center justify-center text-center'>
            <img src={logo} alt='chaizer' className='h-6 mb-4' />
            <h1 className='font-semibold text-2xl text-black mt-3'>{getHeading()}</h1>
          </div>
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
