import axios from 'axios';
import QueryString from 'qs';
import env from '../env';
import { LoginFormData, LogoutFormData } from '../types/oidc';

const OidcApiEndpoints = {
  login: async (data: LoginFormData) => {
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: QueryString.stringify(data),
      url: `${env.REACT_APP_API_URL}/connect/token`,
    };
    return axios(options);
  },
  logout: async (data: LogoutFormData) => {
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: QueryString.stringify(data),
      url: `${env.REACT_APP_API_URL}/connect/revoke`,
    };
    return axios(options);
  },
};

export default OidcApiEndpoints;
