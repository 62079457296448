import { CompanyProfileFormData } from '../types/companies';
import HttpHelpers from './helpers';

const CompaniesApiEndpoints = {
  getCompanyProfile: (code: string | undefined) => HttpHelpers.unAuthenticatedAxios
    .get(`/api/v2/customer/company_profiles/completion/${code}`)
    .then((response) => response.data),
  completeCompanyProfile: (code: string | undefined, data: CompanyProfileFormData) => HttpHelpers.unAuthenticatedAxios
    .post(`/api/v2/customer/company_profiles/completion/${code}`, data)
    .then((response) => response.data),
};

export default CompaniesApiEndpoints;
