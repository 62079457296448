import { LinkSlashIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function CompanyProfileError() {
  const { t } = useTranslation();

  return (
    <div className='flex items-center justify-center min-h-screen text-center'>
      <div>
        <LinkSlashIcon className='h-12 w-12 text-black mx-auto' />
        <p className='mt-3 text-base leading-7 font-bold tracking-tight text-black'>{t('companyProfile.error')}</p>
        <div className='mt-6'>
          <Link
            to='/'
            className='rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600'
          >
            {t('notFound.cta')}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CompanyProfileError;
