import AccountsApiEndpoints from './accounts.api';
import OidcApiEndpoints from './oidc.api';
import * as helpers from './helpers';
import LookupsApiEndpoints from './lookups.api';
import ItemsApiEndpoints from './items.api';
import CompaniesApiEndpoints from './companies.api';

const Api = {
  ...helpers,
  accounts: AccountsApiEndpoints,
  oidc: OidcApiEndpoints,
  lookups: LookupsApiEndpoints,
  items: ItemsApiEndpoints,
  companies: CompaniesApiEndpoints,
};

export default Api;
