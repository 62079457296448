import ReactDOM from 'react-dom/client';
import clevertap from 'clevertap-web-sdk';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';
import App from './App';
import './index.css';
import './i18n';
import env from './env';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

clevertap.init(env.REACT_APP_CLEVERTAP_TOKEN);
clevertap.spa = true;

if (env.REACT_APP_ENVIRONMENT !== 'dev') {
  Sentry.init({
    dsn: 'https://1433b23bf2b456996f45cdef8e5b5e95@o563657.ingest.us.sentry.io/4507050155311104',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/chaizer\.com\/app/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

reportWebVitals();
