import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../services';
import { User } from '../types/user';

export const getProfile = createAsyncThunk('profile/getProfile', async () => {
  const response = await Api.accounts.getProfile();
  return response?.data;
});

interface InitialStateType {
  data: User | null,
  loading: boolean,
}

const initialState: InitialStateType = {
  data: null,
  loading: true,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfileLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.data = null;
        state.loading = true;
      });
    builder
      .addCase(getProfile.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      });
    builder
      .addCase(getProfile.rejected, (state) => {
        state.data = null;
        state.loading = false;
      });
  },
});

export const {
  setProfileLoading,
} = profileSlice.actions;

export default profileSlice.reducer;
