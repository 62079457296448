import React, { useEffect, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { getErrorsByPath } from '../../helpers';
import Api from '../../services';
import { Country } from '../../types/country';
import '../../assets/css/telinput.css';

interface PhoneFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  errors: object;
  control: any;
}

const PhoneField = React.forwardRef(({
  label, name, errors = {}, control, ...props
}: PhoneFieldProps, ref) => {
  React.useImperativeHandle(ref, () => ({}));
  const [countries, setCountries] = useState<string[]>([]);

  useEffect(() => {
    Api.lookups.getCountries().then((response) => {
      const flattenedCountries = response.data?.map((item: { id: string; type: string; attributes: object; }) => ({
        id: item.id,
        type: item.type,
        ...item.attributes,
      }));
      const countryCodes = flattenedCountries.map((country: Country) => country.code2.toLowerCase());
      setCountries(countryCodes);
    });
  }, []);
  return (
    <div className='mb-5'>
      <label className='block text-sm font-medium leading-6 text-black'>
        {label}
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <div className='mt-2' dir='ltr'>
            {countries?.length > 0
            && (
              <PhoneInput
                prefix='+'
                inputClass={classNames(
                  'w-full p-2.5 z-20 rounded-md border-s-0 border-0 py-2.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-primary disabled:bg-zinc-100 sm:text-sm sm:leading-6',
                  { 'ring-red-300': getErrorsByPath(errors, name) },
                )}
                buttonClass={classNames(
                  'relative py-2.5 px-3 text-center text-black bg-gray-100 rounded-s-md text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6',
                  { 'ring-red-300': getErrorsByPath(errors, name) },
                )}
                dropdownClass='absolute z-10 bg-white divide-y divide-gray-100 rounded-md shadow w-52 dark:bg-gray-700'
                value={value}
                disabled={props.disabled}
                inputProps={{
                  onChange,
                }}
                country='sa'
                enableSearch={false}
                onlyCountries={countries}
                autoFormat={false}
              />
            )}
          </div>
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className='text-red-500 text-xs mt-2'>{message}</p>}
      />
    </div>
  );
});

export default PhoneField;
