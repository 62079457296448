import { useState } from 'react';
import { PhoneIcon, EnvelopeIcon } from '@heroicons/react/20/solid';
import {
  Tab, TabGroup, TabList, TabPanel, TabPanels,
} from '@headlessui/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import EmailForm from './email';
import PhoneForm from './phone';

export default function Login() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { t } = useTranslation();

  const tabs = [
    {
      id: 1, title: t('shared.phone'), icon: PhoneIcon,
    },
    {
      id: 2, title: t('shared.email'), icon: EnvelopeIcon,
    },
  ];
  return (
    <>
      <p className='text-center mt-3 mb-10 text-base font-medium text-secondary'>{t('auth.login.subtitle')}</p>
      <TabGroup selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <div className='flex justify-center mb-6'>
          <TabList className='w-full'>
            {tabs.map((tab, index) => (
              <Tab
                key={tab.id}
                className={({ selected }) => classNames(
                  'w-1/2 group inline-flex items-center justify-center border-b-2 py-4 text-sm font-medium focus:outline-none',
                  selected
                    ? 'border-primary text-primary'
                    : 'border-transparent text-secondary hover:border-secondary hover:text-secondary',
                )}
              >
                <tab.icon
                  className={classNames(
                    selectedIndex === index
                      ? 'text-primary' : 'text-secondary group-hover:text-secondary',
                    '-ml-0.5 rtl:ml-2 mr-2 h-5 w-5',
                  )}
                  aria-hidden='true'
                />
                <span>{tab.title}</span>
              </Tab>
            ))}
          </TabList>
        </div>
        <TabPanels>
          <TabPanel>
            <PhoneForm />
          </TabPanel>
          <TabPanel>
            <EmailForm />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </>
  );
}
