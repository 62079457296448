import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { AppDispatch, RootState } from '../../redux/store';
import { logout } from '../../redux/auth';

export default function Home() {
  const { locale } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const profile = useSelector((state: RootState) => state.profile.data);
  const authenticated = useSelector((state: RootState) => state.auth.authenticated);
  const { t } = useTranslation();

  const logoutUser = () => {
    const refreshToken = localStorage.getItem('refresh_token');
    const data = {
      client_id: 'chaizer-webstore',
      client_secret: 'chaizer',
      token_type_hint: 'refresh_token',
      token: refreshToken,
    };
    dispatch(logout(data));
  };
  return (
    <div className='flex min-h-full flex-1 flex-col items-center justify-center'>
      {authenticated
        ? (
          <>
            <h2 className='text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
              {t('home.welcome')}
              ,
              {' '}
              {profile?.full_name}
              !
            </h2>
            <button
              type='button'
              className='flex justify-center rounded-md bg-red-500 px-3 py-2 mt-4 text-base font-medium leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
              onClick={logoutUser}
            >
              {t('header.logout')}
            </button>
          </>
        ) : <Link className='text-center cursor-pointer text-2xl font-bold leading-9 tracking-tight text-primary' to={`/${locale}/auth/login`}>{t('home.login')}</Link>}
    </div>
  );
}
